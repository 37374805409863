import React, { useState } from "react";
import { Link } from "gatsby";
import slugify from "slugify";
// import { useCategories } from "../hooks/useCategories";
// import { Logo } from "./brand";

const Navbar = () => {
  // const categories = useCategories();
  const [menu, setMenu] = useState(false);



  return (
    <div>
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <p className="title is-4">SafeTap</p>
            </Link>

            <div
              className={`navbar-burger burger ${menu ? "is-active" : ""}`}
              data-target="navMenu"
              onClick={() => setMenu(!menu)}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div id="navMenu" className={`navbar-menu ${menu ? "is-active" : ""}`}>
            <div className="navbar-end has-text-centered is-size-5">
              {/* <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link has-text-weight-semibold">
                  How it works
                </a>

                <div className="navbar-dropdown">
                  {categories.map(category => (
                    <Link
                      key={category}
                      to={`/${slugify(category).toLowerCase()}`}
                      className="navbar-item is-size-5"
                      onClick={() => setMenu(false)}
                    >
                      {category}
                    </Link>
                  ))}
                </div>
              </div> */}

              <Link
                className="navbar-item has-text-weight-semibold"
                to="/about"
                onClick={() => setMenu(false)}
              >
                About
              </Link>
              <Link className="navbar-item has-text-weight-semibold" to="/blog"
                onClick={() => setMenu(false)}
              >
                Blog
              </Link>
              <Link
                className="navbar-item has-text-weight-semibold"
                to="/contact"
              >
                Contact
              </Link>
            </div>
            {/* <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons is-centered">
                  <a
                    href="https://app.proukraine.org/"
                    className="button is-ghost has-text-white"
                  >
                    <strong>Partner with us</strong>
                  </a>
                  <a
                    href="https://app.proukraine.org/"
                    className="button is-ghost is-rounded has-text-white"
                  >
                    Sign in
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </nav>

      {/* BOTTOM NAVBAR */}

      {/* <nav
          className="navbar is-black is-fixed-bottom is-hidden-desktop"
          role="navigation"
          aria-label="main-navigation"
        >
          <div className="container">
            <div className="navbar-brand">
              <span className="navbar-item is-hidden-mobile">
                <div className="field is-grouped">
                  <p className="control">
                    <a
                      href="https://app.payment.ninja/signup"
                      className="button is-white"
                    >
                      <strong>Start Free</strong>
                    </a>
                  </p>
                </div>
              </span>
              <span className="navbar-item is-hidden-desktop">
                <div className="field is-grouped">
                  <p className="control">
                    <a
                      href="https://app.payment.ninja/signup"
                      className="button is-medium is-white is-rounded"
                    >
                      <strong>Start Free</strong>
                    </a>
                  </p>
                </div>
              </span>
            </div>

            <div className="navbar-end is-hidden-mobile">
              <Link
                className="navbar-item has-text-weight-semibold"
                to="/news/meet-payment-ninja-app-for-android"
              >
                New! Try our Android app
              </Link>
            </div>
          </div>
        </nav> */}
    </div>
  );
};

export default Navbar;
